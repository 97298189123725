import { createContext, PropsWithChildren, useRef, useContext } from "react";
import { BackendApiService } from "../services/BackendApiService";

interface BackendApiContextType {
    readonly api: BackendApiService,
}

const BackendApiContext = createContext<BackendApiContextType>(
    {
        // eslint-disable-next-line no-unreachable
        get api() { throw new Error('Out of the context scope.'); return new BackendApiService('') },
    }
);

interface BackendApiProviderProps extends PropsWithChildren {
}

export function BackendApiProvider({ children }: BackendApiProviderProps) {
    const apiRef = useRef<BackendApiService>(new BackendApiService(process.env.REACT_APP_API_URL ?? ''));

    return (
        <BackendApiContext.Provider value={{ api: apiRef.current }}>
            {children}
        </BackendApiContext.Provider>
    );
}

export function useBackendApi() {
    return useContext(BackendApiContext);
}
