export const subscriptionStatusMap = (value: string): string => (
    {
        PendingFulfillmentStart: 'Pending activation',
        Subscribed: 'Subscribed',
        Suspended: 'Suspended',
        Unsubscribed: 'Unsubscribed'
    }[value] || value
);

export const periodMap = (value: string): string => (
    {
        P1M: 'Monthly',
        P1Y: 'Yearly'
    }[value] || value
);

export const dateTimeString = (input: string): string => {
    const date = new Date(input);

    return date.toLocaleString('en-us', {
        dateStyle: 'long',
        timeStyle: 'long'
    });
}

export const dateString = (input: string): string => {
    const date = new Date(input);

    return date.toLocaleString('en-us', {
        dateStyle: 'long'
    });
}
