import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

export function AzureLink() {
    const [urlParams] = useSearchParams();
    const navigate = useNavigate();
    const { setMarketplaceToken } = useAuth();

    useEffect(() => {
        if (!urlParams.has('token') || urlParams.get('token')!.length === 0) {
            console.error('Marketplace token not provided.');
            navigate('/unauthorized');

            return;
        }
        setMarketplaceToken(urlParams.get('token')!.replaceAll(' ', '+'));

        navigate('/subscriber');
    }, [navigate, setMarketplaceToken, urlParams]);

    return null;
}
