import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { AzureLink } from "./components/AzureLink";
import { PageLayout } from "./components/PageLayout";
import { Subscriber } from "./components/Subscriber";
import { Unauthorized } from "./components/Unauthorized";
import { AuthProvider } from "./contexts/AuthContext";
import { BackendApiProvider } from "./contexts/BackendApiContext";

function App() {
    return (
        <BackendApiProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<AuthProvider><PageLayout /></AuthProvider>}>
                        <Route path="azure" element={<AzureLink />} />
                        <Route path="subscriber" element={<Subscriber />} />
                        <Route path="*" element={<Navigate to="/unauthorized" replace={true} />} />
                        <Route index element={<Navigate to="/unauthorized" replace={true} />} />
                    </Route>
                    <Route path="/unauthorized" element={<Unauthorized />} />
                </Routes>
            </Router>
        </BackendApiProvider>
    );
}

export default App;
