import { PulseLoader } from "react-spinners";

interface LoaderProps {
    className?: string
}

export function Loader({ className }: LoaderProps) {
    return (
        <div className={className}>
            <div className="w-fit m-auto">
                <PulseLoader size="0.5rem" color="#0067B8" cssOverride={{ display: 'block' }} />
            </div>
        </div>
    );
}
