import { PropsWithChildren } from "react";
import * as TooltipRadix from '@radix-ui/react-tooltip';

interface TooltipProps extends PropsWithChildren {
    message: string
}

export function Tooltip({ children, message }: TooltipProps) {
    return (
        <TooltipRadix.Provider>
            <TooltipRadix.Root>
                <TooltipRadix.Trigger asChild>
                    {children}
                </TooltipRadix.Trigger>
                <TooltipRadix.Portal>
                    <TooltipRadix.Content className="TooltipContent" sideOffset={5}>
                        <span className="shadow px-2 py-1 bg-slate-50 text-xs">{message}</span>
                    </TooltipRadix.Content>
                </TooltipRadix.Portal>
            </TooltipRadix.Root>
        </TooltipRadix.Provider>
    );
}
