import { PropsWithChildren } from "react";
import { Outlet } from "react-router";

interface PageLayoutProps extends PropsWithChildren {
}

interface RowProps extends PropsWithChildren {
}

interface ColumnProps extends PropsWithChildren {
    first?: boolean
}

export function PageLayout({ children }: PageLayoutProps) {
    return (
        <div className="absolute inset-0 bg-slate-100 overflow-auto">
            <header className="absolute z-10 border-b w-full bg-white shadow-md">
                <div className="max-w-6xl mx-auto px-4 sm:px-6 md:px-8 h-20 py-4">
                    <img
                        src="https://canaryspeech.com/wp-content/uploads/2022/07/CS-Logo-Purple-Text-Blue-Icon.png"
                        alt="Canary Speech Logo"
                        className="max-h-full"
                    />
                </div>
            </header>

            <div className="relative max-w-6xl mx-auto px-4 sm:px-6 md:px-8 pb-4 sm:pb-6 md:pb-8 pt-24 sm:pt-28 md:pt-32 min-h-full bg-white">
                <Outlet />
            </div>
        </div>
    );
}

export function Row({ children }: RowProps) {
    return (
        <div className="flex items-stretch gap-2 w-full border-t-2 px-2 pt-8">
            {children}
        </div>
    );
}

export function Column({ children, first = false }: ColumnProps) {
    return (
        <div className={"flex flex-col basis-0 grow px-2" + (first ? "" : " border-l-2 pl-4")}>
            {children}
        </div>
    );
}
