import { ApiService } from "./ApiService";

export class BackendApiService {
    private apiService: ApiService;

    constructor(baseUrl: string) {
        this.apiService = new ApiService(baseUrl);
    }

    resolveMarketplaceToken = (marketplaceToken: string, accessToken: string) => {
        return this.apiService.post('/ResolveMarketplaceToken', { marketplaceToken }, this.apiService.authHeader(accessToken));
    }

    requestSubscriptionActivation = (marketplaceToken: string, accessToken: string) => {
        return this.apiService.post('/RequestSubscriptionActivation', { marketplaceToken }, this.apiService.authHeader(accessToken));
    }

    getSubscriptionData = (marketplaceToken: string, accessToken: string) => {
        return this.apiService.post('/GetSubscriptionData', { marketplaceToken }, this.apiService.authHeader(accessToken));
    }
}
