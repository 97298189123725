export class ApiService {
    private baseUrl: string;

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    get(endpoint: string, headers?: HeadersInit) {
        const options = {
            method: 'GET',
            headers
        }
        return this.fetch(endpoint, options);
    }

    post(endpoint: string, data: Record<string, any> | null, headers?: HeadersInit) {
        const options = {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        }
        return this.fetch(endpoint, options);
    }

    patch(endpoint: string, data: Record<string, any>, headers?: HeadersInit) {
        const options = {
            method: 'PATCH',
            headers,
            body: JSON.stringify(data)
        }
        return this.fetch(endpoint, options);
    }

    delete(endpoint: string, headers?: HeadersInit) {
        const options = {
            method: 'DELETE',
            headers
        }
        return this.fetch(endpoint, options);
    }

    authHeader = (token: string) => (
        {
            'Authorization': 'Bearer ' + token
        }
    );

    private fetch = (endpoint: string, options: RequestInit) => {
        return fetch(this.baseUrl + endpoint, options);
    }
}